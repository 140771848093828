.card-container{
    border: 1px solid lightgray;
    box-shadow: 5px 5px 5px gray;
    width: 400px;
    height: 400px;
    overflow: hidden;
    margin: 10px;
    cursor: pointer;
}

.card-content p{
    margin: 0 auto;
}

.card-content h1, .card-content p{
    width: 90%;
}

.card-container img{
    object-fit: cover;
    position: relative;
    opacity: 0.6;
    height: 150px;
    width: 100%;
}

.funding{
    display: flex;
    justify-content: center;
    max-width: 290px;
    margin: 0 auto;
    margin-top: 20px;
    height: 25px;
    color: white;
}

.funding p{
    margin: 0;
}

.low-funding{
    background-color: red;
}

.mid-funding{
    background-color:yellow;
}

.mid-funding p{
    color: black
}

.high-funding{
    background-color: green;
}

@media all and (max-width: 800){
    .card-container{
        width: 80%;
    }
}