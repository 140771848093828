.landing-container{
    display:flex;
    align-items:center;
    height: 80vh;
}
.landing-container::after{
    content:'';
    background: url("../images/elephant.jpg") no-repeat;
    background-size:cover;
    opacity: 0.8;
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: -1;
}

.landing-content {
    margin: 0 auto;
    display:flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items:center;
    height: 500px;
    width: 800px;
    position: relative;
    z-index: 2;
}

.landing-content::after{
    content: "";
    background: url("#") black;
    opacity: 0.8;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: -1;
    border-radius: 5px;
}

.landing-content p{
    color: #f5f5f5;
    width: 80%;
}

.org-campaigns, .sup-campaigns{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    margin-top: 20px;
}

@media all and (max-width: 1000px) {
    .landing-container::after{
        background: url('../images/elephant_small.jpg') no-repeat;
        background-size: cover;
    }
}

@media all and (max-width: 800px) {
    .landing-content{
        height: 70%;
    }
}