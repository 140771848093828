.register-content{
    display:flex;
    align-items:center;
    height: 80vh;
}
.register-content::after{
    content:'';
    background: url("../images/zebra.jpg") no-repeat;
    background-size: cover;
    opacity: 0.8;
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: -1;
}

.register-form-container {
    margin: 0 auto;
    display:flex;
    justify-content: center;
    align-items:center;
}

Form::after{
    content: "";
    background: url("#") black;
    opacity: 0.8;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: -1;
    border-radius: 5px;
}

Form button{
    width: 100px;
    height: 30px;
    border-radius: 3.75rem;
    border: 0;
    background-color: #FFD700;
    box-shadow: 2px 2px rgb(180, 153, 0);
    margin-top: 5px;
}

Form button:active{
    background-color: rgb(180, 153, 0);
}

Form button:focus{
    outline: none;
    cursor: progress;
}

Form label{
    color: #f5f5f5;
}

Form input{
    margin: 4%;
}
.type-org, .type-sup{
    display: flex;
    justify-content: center;
    margin: 4%;
}

.type-org input, .type-sup input{
    margin: 0;
    width: 40px;
}

@media all and (max-width: 1000px) {
    .register-content::after{
        background: url('../images/zebra_small.jpg') no-repeat;
        background-size: cover;
    }
}