.content{
    display:flex;
    align-items:center;
    height: 80vh;
}
.content::after{
    content:'';
    background: url("../images/rhino.jpg") no-repeat;
    background-size: cover;
    opacity: 0.8;
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: -1;
}

.form-container {
    margin: 0 auto;
    display:flex;
    justify-content: center;
    align-items:center;
}
  

Form{
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    height: 500px;
    width: 500px;
    position: relative;
    z-index: 2;
}

@media all and (max-width: 500px) {
    .form-container{
        width: 500px;
    }
    .register-form-container{
        width:500px;
    }
    Form{
        width: 80%;
    }
}

Form::after{
    content: "";
    background: url("#") black;
    opacity: 0.8;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: -1;
    border-radius: 5px;
}

Form button{
    width: 100px;
    height: 30px;
    border-radius: 3.75rem;
    border: 0;
    background-color: #FFD700;
    box-shadow: 2px 2px rgb(180, 153, 0);
    margin-top: 5px;
}

Form label{
    color: #f5f5f5;
}

Form input{
    margin: 4%;
}

#org-add-campaign, #org-edit-campaign{
    height: 600px;
    margin: 0 auto;
    margin-top: 20px;
}

@media all and (max-width: 1000px) {
    .content::after{
        background: url('../images/rhino_small.jpg') no-repeat;
        background-size:cover;
    }
}

input{
    border-radius: 5px;
}
  
input:focus{
    border: 2px solid #FFD700;
}
  
select{
    display: block;
    font-size: 16px;
    font-family: sans-serif;
    font-weight: 700;
    color: #444;
    line-height: 1;
    padding: .4em 1.2em .35em .6em;
    width: 102%;
    max-width: 102%;
    box-sizing: border-box;
    margin: 0;
    border: 2px solid #aaa;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
    border-radius: .5em;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url('data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E'),
    linear-gradient(to bottom, #ffffff 0%,#e5e5e5 100%);
    background-repeat: no-repeat, repeat;
    background-position: right .7em top 50%, 0 0;
    background-size: .65em auto, 100%;
}
  
select::-ms-expand {
    display: none;
}
select:hover {
    border-color: #888;
}
select:focus {
    border-color: #FFD700;
    color: #222;
    outline: none;
}
select option {
    font-weight:normal;
}